import React from 'react';
import { motion } from 'framer-motion';
import section2BgImage from '../assets/images/section-2-bg.png';

export default function Section4({ score, visible }) {
  return (
    <section
      className="relative flex h-screen w-screen flex-col items-center justify-center overflow-hidden"
      style={{ height: window.innerHeight }}
    >
      <img
        src={section2BgImage}
        alt="background"
        className="absolute inset-0 h-full w-full object-cover"
      />
      <div className="flex h-full w-full max-w-[1060px] flex-col items-center justify-center px-30">
        <div className="relative flex w-full flex-col items-center justify-center gap-28 md:gap-25">
          <motion.h2
            className="text-center text-30 font-[600] leading-[36px] tracking-tighter text-[#0b0a2b] md:text-54 md:leading-[70px]"
            initial={{
              opacity: 0,
              y: 15,
            }}
            transition={
              visible && {
                delay: 0.5,
                duration: 0.5,
              }
            }
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                opacity: 0,
                y: 15,
              },
            }}
            animate={visible ? 'visible' : 'hidden'}
          >
            전립선 자가진단
            <br className="md:hidden" />
            <span className="hidden md:inline"> </span>
            <b className="text-[#1921be]">무료 설문조사 결과</b>
          </motion.h2>
          <motion.div
            className="relative flex w-full flex-col items-center justify-center gap-25 rounded-18 bg-[#ffffff] px-15 py-35 shadow-xl md:gap-25 md:rounded-30 md:px-0 md:py-25"
            initial={{
              opacity: 0,
              y: 15,
            }}
            transition={
              visible && {
                delay: 1,
                duration: 0.5,
              }
            }
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                opacity: 0,
                y: 15,
              },
            }}
            animate={visible ? 'visible' : 'hidden'}
          >
            <p className="text-center text-20 leading-[26px] tracking-tighter text-[#0b0a2b] md:text-32 md:leading-[40px]">
              설문에 참여해주셔서 감사합니다.
              <br />
              <b className="font-[600]">귀하의 전립선 자가진단 결과</b>
            </p>
            <p className="rounded-full bg-[#e7e8f8] px-50 py-12 text-center text-20 tracking-tighter text-[#0b0a2b] md:text-36">
              <b className="text-28 font-[600] text-[#1921be] md:text-48">
                {score}점
              </b>{' '}
              입니다.
            </p>
            <div className="flex w-full flex-col items-center justify-center gap-5 rounded-10 border border-[#1921be] py-15 text-11 leading-[17px] text-[#1921be] md:w-auto md:gap-10 md:rounded-30 md:px-50 md:py-25 md:text-26 md:leading-[32px]">
              <div className="px-15 md:px-0">
                <span className="text-14 leading-[17px] text-[#1921be] md:text-26 md:leading-[32px]">
                  <b className="mb-5 block text-center font-[600]">
                    [IPSS 자가진단 점수별 상태]
                  </b>
                  1~7점 경미
                  <br />
                  8~19점 중등도 상태로 치료 필요
                  <br />
                  20~35점 심각한 상태로 남성 기능을 하기 어려운 단계
                </span>
              </div>
            </div>
            <p className="break-keep text-center text-16 font-[600] leading-[24px] tracking-tighter text-[#0b0a2b] md:hidden md:text-26 md:leading-[36px]">
              설문조사에 응해주신 보답으로 드리는 선물은
              <br />
              영업일 기준 5일 이내에 담당자가
              <br />
              직접 연락을 드린 후 지급될 예정입니다.
            </p>
            <p className="hidden break-keep text-center text-16 font-[600] leading-[24px] tracking-tighter text-[#0b0a2b] md:block md:text-26 md:leading-[36px]">
              설문조사에 응해주신 보답으로 드리는 선물은
              <br />
              영업일 기준 5일 이내에 담당자가 직접 연락을 드린 후 지급될
              예정입니다.
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
