import React from 'react';
import section3BgImage from '../assets/images/section-3-bg.png';
import section3CheckImage from '../assets/images/section-3-check.png';
import Radio from './radio';
import { motion } from 'framer-motion';

const QUESTIONS = [
  {
    title:
      '평소 소변을 볼 때 다 보았는데도 소변이 남아있는 것 같이 느끼는 경우가 있나요?',
    questions: [
      '전혀없음',
      '5번중 1번',
      '5번중 1-2번',
      '5번중 2-3번',
      '5번중 3-4번',
      '거의 항상',
    ],
  },
  {
    title:
      '평소 소변을 보고 난 후 2시간 이내에 다시 소변을 보는 경우가 있습니까?',
    questions: [
      '전혀없음',
      '5번중 1번',
      '5번중 1-2번',
      '5번중 2-3번',
      '5번중 3-4번',
      '거의 항상',
    ],
  },
  {
    title:
      '평소 소변을 볼 때 소변줄기가 끊어져서 다시 힘줘 소변을 보는 경우가 있습니까?',
    questions: [
      '전혀없음',
      '5번중 1번',
      '5번중 1-2번',
      '5번중 2-3번',
      '5번중 3-4번',
      '거의 항상',
    ],
  },
  {
    title: '평소 소변을 참기 어려운 경우가 있습니까?',
    questions: [
      '전혀없음',
      '5번중 1번',
      '5번중 1-2번',
      '5번중 2-3번',
      '5번중 3-4번',
      '거의 항상',
    ],
  },
  {
    title: '평소 소변줄기가 약하거나 가늘다고 생각되는 경우가 있습니까?',
    questions: [
      '전혀없음',
      '5번중 1번',
      '5번중 1-2번',
      '5번중 2-3번',
      '5번중 3-4번',
      '거의 항상',
    ],
  },
  {
    title:
      '평소 소변을 볼 때 소변이 금방 나오지 않아서 아랫배에 힘을 주어야 하는 경우가 있습니까?',
    questions: [
      '전혀없음',
      '5번중 1번',
      '5번중 1-2번',
      '5번중 2-3번',
      '5번중 3-4번',
      '거의 항상',
    ],
  },
  {
    title:
      '평소 잠을 자다 일어나서 소변을 보는 경우가 하룻밤에 몇 번이나 있습니까?',
    questions: ['없음', '1번', '2번', '3번', '4번', '5번'],
  },
];

export default function Section3({ visible, register }) {
  return (
    <section
      className="relative h-screen w-screen overflow-hidden"
      style={{ height: window.innerHeight }}
    >
      <img
        src={section3BgImage}
        alt="background"
        className="absolute inset-0 h-full w-full object-cover"
      />
      <div className="relative flex h-full w-full flex-col items-center overflow-y-auto overflow-x-hidden pb-55 pt-80 md:py-60">
        <div className="relative flex w-full max-w-[1060px] flex-col items-center gap-35 px-30 md:gap-75">
          <div className="flex flex-col items-center gap-20 md:gap-30">
            <motion.h2
              className="text-center text-30 font-[600] leading-[38px] tracking-tighter text-[#0b0a2b] md:text-54 md:leading-[70px]"
              initial={{
                opacity: 0,
                y: 15,
              }}
              transition={
                visible && {
                  delay: 0.5,
                  duration: 0.5,
                }
              }
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                },
                hidden: {
                  opacity: 0,
                  y: 15,
                },
              }}
              animate={visible ? 'visible' : 'hidden'}
            >
              7개 문항으로 알아보는
              <br />
              <b className="text-[#1921be]">내 전립선 건강 점수!</b>
            </motion.h2>
            <motion.div
              className="flex items-center justify-center gap-5 md:gap-15"
              initial={{
                opacity: 0,
                y: 15,
              }}
              transition={
                visible && {
                  delay: 1,
                  duration: 0.5,
                }
              }
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                },
                hidden: {
                  opacity: 0,
                  y: 15,
                },
              }}
              animate={visible ? 'visible' : 'hidden'}
            >
              <img
                src={section3CheckImage}
                alt="check"
                className="w-8 md:w-21"
              />
              <p className="text-17 font-[400] tracking-tighter text-[#f30d1e] md:text-30">
                자가진단 대상자 | <b className="font-[600]">60대 이상 남성</b>
              </p>
            </motion.div>
            <motion.p
              className="rounded-full bg-[#e7e8f8] px-25 py-10 text-center text-18 font-[600] tracking-tighter text-[#1921be] md:px-50 md:py-20 md:text-36"
              initial={{
                opacity: 0,
                y: 15,
              }}
              transition={
                visible && {
                  delay: 1,
                  duration: 0.5,
                }
              }
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                },
                hidden: {
                  opacity: 0,
                  y: 15,
                },
              }}
              animate={visible ? 'visible' : 'hidden'}
            >
              30초만에! 전립선 건강 자가 진단하기
            </motion.p>
          </div>
          <motion.div
            className="flex w-full flex-col items-center rounded-20 bg-[#ffffff] px-20 pb-20 shadow-xl md:rounded-30 md:px-50 md:pb-40"
            initial={{
              opacity: 0,
              y: 15,
            }}
            transition={
              visible && {
                delay: 1.5,
                duration: 0.5,
              }
            }
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                opacity: 0,
                y: 15,
              },
            }}
            animate={visible ? 'visible' : 'hidden'}
          >
            {QUESTIONS.map((question, questionIndex) => {
              return (
                <div
                  key={`Question-${questionIndex}`}
                  className="flex flex-col justify-center gap-15 border-b-[#cccef4] px-15 py-25 md:gap-25 md:py-50 [&:not(:last-of-type)]:border-b"
                >
                  <div className="flex flex-col items-center justify-center">
                    <motion.p
                      className="break-keep text-center text-20 font-[600] leading-[25px] tracking-tighter text-[#000000] md:whitespace-pre md:text-24 md:leading-[40px]"
                      initial={{
                        opacity: 0,
                        y: 15,
                      }}
                      transition={{
                        delay: 0.5,
                        duration: 0.5,
                      }}
                      variants={{
                        visible: {
                          opacity: 1,
                          y: 0,
                        },
                        hidden: {
                          opacity: 0,
                          y: 15,
                        },
                      }}
                      animate={visible ? 'visible' : 'hidden'}
                    >
                      <b className="text-[#1921be]">Q{questionIndex + 1}.</b>{' '}
                      {question.title}
                    </motion.p>
                  </div>
                  <motion.div
                    className="flex flex-col gap-5 md:flex-row md:justify-between md:gap-15"
                    initial={{
                      opacity: 0,
                      y: 15,
                    }}
                    transition={{
                      delay: 1,
                      duration: 0.5,
                    }}
                    variants={{
                      visible: {
                        opacity: 1,
                        y: 0,
                      },
                      hidden: {
                        opacity: 0,
                        y: 15,
                      },
                    }}
                    animate={visible ? 'visible' : 'hidden'}
                  >
                    {question.questions.map((child, childIndex) => {
                      return (
                        <Radio
                          key={`Question-${questionIndex}-Question-${childIndex}`}
                          {...register(`questions[${questionIndex}].value`)}
                          value={childIndex}
                        >
                          {child}
                        </Radio>
                      );
                    })}
                  </motion.div>
                </div>
              );
            })}
            <motion.button
              type="submit"
              onClick={(event) => {
                for (let i = 0; i < QUESTIONS.length; i++) {
                  if (
                    window.document.form[`questions[${i}].value`].value === ''
                  ) {
                    alert(`Q${i + 1}을 선택해주세요.`);
                    event.preventDefault();
                    return;
                  }
                }
              }}
              className="relative flex h-48 w-full md:h-90 md:w-540"
              initial={{
                opacity: 0,
                y: 15,
              }}
              transition={
                visible && {
                  delay: 1.5,
                  duration: 0.5,
                }
              }
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                },
                hidden: {
                  opacity: 0,
                  y: 15,
                },
              }}
              animate={visible ? 'visible' : 'hidden'}
            >
              <div className="relative flex h-full w-full items-center justify-center rounded-full bg-gradient-to-r from-[#060b6a] to-[#1821be] text-center text-24 font-[600] tracking-tighter text-[#ffffff] shadow md:h-90 md:w-540 md:text-36">
                다음으로
                <span className="absolute right-20 text-16 tracking-tighter md:right-35 md:text-30">
                  →
                </span>
              </div>
            </motion.button>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
