import React from 'react';
import classNames from '../utils/class-names';

const Radio = React.forwardRef(function Radio(
  { children, className, ...props },
  ref,
) {
  return (
    <label
      className={classNames(
        'flex items-center gap-6 text-16 font-[400] tracking-tighter text-[#000000] md:text-24',
        className,
      )}
    >
      <input ref={ref} type="radio" className="peer hidden" {...props} />
      <div className="h-16 w-16 rounded-full border-2 border-[#cccef4] p-2 md:h-24 md:w-24 md:border-3 md:p-3 peer-checked:[&>div]:block">
        <div className="hidden h-full w-full rounded-full bg-[#1921be]" />
      </div>
      {children}
    </label>
  );
});

export default Radio;
