import React from 'react';
import Radio from './radio';
import { motion } from 'framer-motion';
import classNames from '../utils/class-names';

export default function Section2({ visible, onNext, score, register }) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <section
      className="relative flex h-screen w-screen flex-col items-center justify-center overflow-hidden bg-[#ffffff] pb-50 pt-70 md:py-60"
      style={{ height: window.innerHeight }}
    >
      <div className="flex h-full w-full max-w-[1060px] flex-col items-center justify-between px-30">
        <div className="relative flex w-full flex-col items-center justify-center gap-20 md:gap-30">
          <motion.h2
            className="text-center text-26 font-[600] leading-[32px] tracking-tighter text-[#0b0a2b] md:text-42 md:leading-[60px]"
            initial={{
              opacity: 0,
              y: 15,
            }}
            transition={
              visible && {
                delay: 0.5,
                duration: 0.5,
              }
            }
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                opacity: 0,
                y: 15,
              },
            }}
            animate={visible ? 'visible' : 'hidden'}
          >
            전립선 자가진단 무료 설문조사
            <br />
            <b className="text-[#1921be]">아래 정보를 입력해주세요.</b>
          </motion.h2>
          <motion.div
            className="flex w-full max-w-[735px] flex-col gap-14"
            initial={{
              opacity: 0,
              y: 15,
            }}
            transition={
              visible && {
                delay: 1.5,
                duration: 0.5,
              }
            }
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                opacity: 0,
                y: 15,
              },
            }}
            animate={visible ? 'visible' : 'hidden'}
          >
            <label className="flex w-full flex-col gap-5">
              <span className="text-16 tracking-tighter text-[#000000] md:text-24">
                <b className="font-[600]">이름</b> * 가명 가능
              </span>
              <input
                type="text"
                className="block h-42 w-full border border-[#d9d9d9] p-5 text-16 tracking-tighter text-[#000000] md:h-76 md:p-15 md:text-24"
                maxLength={10}
                onKeyDown={(event) => {
                  if (
                    event.key.length === 1 &&
                    /^[^a-zA-Zㄱ-힣]*$/.test(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                {...register('name')}
              />
            </label>
            <div className="flex items-center justify-between gap-30">
              <label className="flex w-full flex-1 flex-col gap-5">
                <span className="text-16 tracking-tighter text-[#000000] md:text-24">
                  <b className="font-[600]">나이</b> * 실제 나이
                </span>
                <div className="flex flex-1 items-center gap-15">
                  <input
                    type="text"
                    className="block h-42 w-0 flex-1 border border-[#d9d9d9] p-5 text-16 tracking-tighter text-[#000000] md:h-76 md:p-15 md:text-24"
                    maxLength={3}
                    pattern="[0-9]*"
                    inputMode="numeric"
                    onKeyDown={(event) => {
                      if (event.key.length === 1 && /\D/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register('age')}
                  />
                  <span className="text-16 tracking-tighter text-[#000000] md:text-24">
                    세
                  </span>
                </div>
              </label>
              <div className="flex flex-1 items-center gap-10 pt-26 md:gap-20 md:pt-40">
                <span className="text-16 tracking-tighter text-[#000000] md:text-24">
                  성별
                </span>
                <Radio {...register('sex')} value="male">
                  남성
                </Radio>
              </div>
            </div>
            <label className="flex w-full flex-col gap-5">
              <span className="text-16 tracking-tighter text-[#000000] md:text-24">
                <b className="font-[600]">연락처</b> * 선물 제공을 위한 전화번호
              </span>
              <div className="flex flex-1 items-center gap-5 overflow-hidden font-[400] text-[#000000] md:gap-10 md:text-24">
                <select
                  className="block h-42 w-0 flex-1 border border-[#d9d9d9] p-5 text-16 tracking-tighter text-[#000000] md:h-76 md:p-15 md:text-24"
                  {...register('phone1')}
                >
                  <option>010</option>
                  <option>011</option>
                  <option>016</option>
                  <option>017</option>
                  <option>018</option>
                  <option>019</option>
                </select>
                -
                <input
                  type="text"
                  className="block h-42 w-0 flex-1 border border-[#d9d9d9] p-5 text-16 tracking-tighter text-[#000000] md:h-76 md:p-15 md:text-24"
                  maxLength={4}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  onKeyDown={(event) => {
                    if (event.key.length === 1 && /\D/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  {...register('phone2')}
                />
                -
                <input
                  type="text"
                  className="block h-42 w-0 flex-1 border border-[#d9d9d9] p-5 text-16 tracking-tighter text-[#000000] md:h-76 md:p-15 md:text-24"
                  maxLength={4}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  onKeyDown={(event) => {
                    if (event.key.length === 1 && /\D/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  {...register('phone3')}
                />
              </div>
            </label>
          </motion.div>
        </div>
        <motion.button
          type="button"
          onClick={() => {
            if (window.document.form.name.value === '') {
              alert('이름을 입력해주세요.');
              return;
            }
            if (
              window.document.form.phone2.value.length !== 4 ||
              window.document.form.phone3.value.length !== 4
            ) {
              alert('연락처를 입력해주세요.');
              return;
            }
            if (window.document.form.age.value === '') {
              alert('나이를 입력해주세요.');
              return;
            }
            onNext();
          }}
          className="relative flex h-48 w-290 md:h-90 md:w-540"
          initial={{
            opacity: 0,
            y: 15,
          }}
          transition={
            visible && {
              delay: 1.5,
              duration: 0.5,
            }
          }
          variants={{
            visible: {
              opacity: 1,
              y: 0,
            },
            hidden: {
              opacity: 0,
              y: 15,
            },
          }}
          animate={visible ? 'visible' : 'hidden'}
        >
          <div className="relative flex h-full w-full items-center justify-center rounded-full bg-gradient-to-r from-[#e64405] to-[#ff6e1f] text-center text-24 font-[600] tracking-tighter text-[#ffffff] shadow md:text-36">
            다음으로
            <span className="absolute right-20 text-16 tracking-tighter md:right-35 md:text-30">
              →
            </span>
          </div>
        </motion.button>
      </div>
      <div
        className={classNames(
          'absolute inset-0 z-50 h-full w-full flex-col items-center justify-center',
          isOpen ? 'flex' : 'hidden',
        )}
      >
        <motion.div
          className="absolute inset-0 h-full w-full bg-[#ffffff] opacity-80"
          initial={{
            opacity: 0,
          }}
          transition={
            visible &&
            isOpen && {
              duration: 0.25,
            }
          }
          variants={{
            visible: {
              opacity: 1,
            },
            hidden: {
              opacity: 0,
            },
          }}
          animate={isOpen ? 'visible' : 'hidden'}
        />
        <div className="relative flex w-full max-w-[1060px] flex-col items-center gap-90 px-15 md:gap-125">
          <motion.div
            className={classNames(
              'flex h-[50vh] w-full flex-col items-center gap-15 overflow-auto border-4 bg-[#ffffff] p-15 md:gap-50 md:border-10 md:p-50',
              score > 7 ? 'border-[#ff4d4f]' : 'border-[#1921be]',
            )}
            initial={{
              opacity: 0,
              y: 15,
            }}
            transition={
              visible &&
              isOpen && {
                delay: 0.25,
                duration: 0.5,
              }
            }
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                opacity: 0,
                y: 15,
              },
            }}
            animate={visible && isOpen ? 'visible' : 'hidden'}
          >
            <p className="text-14 font-[400] tracking-tighter">
              미래앤플러스 주식회사(이하 “회사”)는 고객의 개인정보를 중요시
              하며, 『정보통신망이용 촉진 및 정보 보호 등에 관한 법률 』 상의
              개인정보보호 규정 및 정보통신부가 제정한 『 개인정보보호 및
              취급방침』을 준수하고 있습니다. 회사는 개인정보취급방침을 통해
              아래와 같이 제공해주시는 개인정보의 수집 및 이용목적과
              개인정보보호를 위한 회사의 조치 사항을 고지합니다.
              <br />
              <br />
              1. 개인정보 수집 이용 제공 등에 대한 동의
              <br />
              회사는 고객이 회사가 제공하는 안내 및 상담신청에 귀하가 제공한
              정보는 개인정보취급방침에 명시된 내용 이외의 목적으로는 사용되지
              아니합니다.
              <br />
              <br />
              2. 수집하는 개인정보의 범위 및 수집방법
              <br />
              회사에서는 귀하의 상담신청 및 서비스신청 등을 위한 필수적인 정보를
              입력 받고 있습니다.
              <br />
              <br />
              - 필수정보 : 성명, 연락처
              <br />
              - 선택정보 : 나이, 성별, 문의사항, 관심분야, 자녀성별, 자녀나이 등
              <br />
              - 서비스 이용과정이나 처리 과정에서 자동으로 생성되어 수집될 수
              있는 정보 : IP Address, 참여일시, 유입경로(참여매체)
              <br />
              3. 개인정보의 수집 및 이용목적
              <br />
              입력하신 정보는 개인별 맞춤 서비스 제공, 이벤트 안내(이메일, 전화,
              MMS 등), 상품안내를 위한 마케팅 자료(전화, 이메일, MMS 등)로 활용
              됩니다.
              <br />
              <br />
              4. 개인정보의 보유 및 이용기간
              <br />
              개인정보를 수집 동의일로부터 5년간 보유 및 활용하게 되고, 위
              보유기간의 경과 즉시 기록을 재생할 수 없는 기술적 방법을 사용하여
              동 개인정보를 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로
              명시한 기간 동안 보존합니다.
              <br />
              <br />
              ㆍ관련법령에 의한 정보보유 사유
              <br />
              <br />
              - 필수정보 : 성명, 연락처
              <br />
              - 선택정보 : 나이, 성별, 문의사항, 관심분야, 자녀성별, 자녀나이 등
              <br />
              - 서비스 이용과정이나 처리 과정에서 자동으로 생성되어 수집될 수
              있는 정보 : IP Address, 참여일시, 유입경로(참여매체)
              <br />
              5. 개인정보의 파기절차 및 방법
              <br />
              원칙적으로, 개인정보 수집 및 이용목적이 달성되거나, 개인정보 보유
              및 이용기간이 종료한 경우 해당 정보를 지체 없이 파기합니다.
              파기절차 및 방법은 다음과 같습니다.
              <br />
              <br />
              ㆍ파기절차
              <br />
              <br />
              귀하가 참여한 고객정보는 목적이 달성된 후 내부 방침 및 기타 관련
              법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정기간
              저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는
              보유 이외의 다른 목적으로 이용되지 않습니다.
              <br />
              ㆍ파기방법
              <br />
              <br />
              종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
              없는 기술적 방법을 사용하여 삭제합니다.
              <br />
              6. 개인정보 수집 이용 제공 등에 대한 동의철회
              <br />
              귀하는 개인정보의 수집 이용 제공에 대한 동의를 본인 및
              법정대리인을 통해 철회할 수 있습니다. 동의철회는 명시된 문의전화
              또는 이메일 등을 통해 가능합니다.
              <br />
              <br />
              7. 개인정보의 제 3자 제공 및 취급
              <br />
              위탁회사는 귀하의 개인정보를 &lt;개인정보의 수집목적 및
              이용목적&gt;에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여
              이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다. 그러나 보다
              나은 서비스 제공을 위하여 필요할 경우, 귀하의 개인 정보를
              제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를
              제공하거나 공유할 경우에는 사전에 귀하의 동의를 구하는 절차를
              거치게 됩니다.
              <br />
              <br />
              8. 개인정보 처리 업무의 위탁
              <br />
              수락자 위탁사무 및 목적 보유 및 이용기간
              <br />
              미래앤플러스㈜ 회원 정보 DB시스템 운영/관리 업무 고객 통화,
              이메일, 문자메시지 발송 제품의 상담 및 판매, 배송 등의 전반
              고객상담 및 불만처리 업무 전반 고객동의 철회 또는 위탁 계약 만료
              때 까지
              <br />
              이노페이 본인인증(금융거래, 금융서비스) 결제대행 고객동의 철회
              또는 위탁 계약 만료 때 까지
              <br />
              우체국 주문 상품의 배송 고객동의 철회 또는 위탁 계약 만료 때 까지
              <br />
              ㈜다인커뮤니케이션 시스템 유지 보수 및 관리, 상담 신청자
              개인정보를 서버에 수집,보관, 처리, 전달, 파기하는 업무 서버
              보관(IDC 센터) 고객동의 철회 또는 위탁 계약 만료 때 까지
              <br />
              ㈜미래앤플러스
              <br />
              ㈜혜윰
              <br />
              ㈜미래앤플러스 상담 신청자 개인정보를 서버에 수집,보관, 처리,
              전달, 파기하는 업무 고객동의 철회 또는 위탁 계약 만료 때 까지
              <br />
              9. 개인정보관리책임자 및 민원서비스
              <br />
              귀하의 개인정보를 보호하고 관련한 불만을 처리하기 위하여 회사는
              개인정보관리책임자를 두어 귀하의 개인정보를 관리하고 있습니다.
              <br />
              <br />
              ㆍ개인정보관리책임자
              <br />
              <br />
              - 성 명 : 김용일
              <br />
              - 직 책 : 대표이사
              <br />
              - 연락처 : 042-716-3354
              <br />
              - 이메일 : gng365@gng365.com
              <br />
              ㆍ개인정보 보호 담당부서
              <br />
              <br />
              - 부서명 : 마케팅팀
              <br />
              - 담당자 : 양명주 주임
              <br />
              - 연락처 : 042-716-3354
              <br />
              - 이메일: gng365@gng365.com
              <br />
              정보주체 께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한
              모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
              개인정보 관리책임자 및 담당부서로 문의하실 수 있습니다. 회사는
              정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
              <br />
              정보주체는 개인정보 보호법 제35조 및 정보통신망법 제30조에 따른
              개인정보의 열람 청구를 관련 부서에 할 수 있습니다. 회사는
              정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
              <br />
              <br />
              10. 개인정보 열람청구
              <br />
              정보주체는 개인정보 보호법 제35조 및 정보통신망법 제30조에 따른
              개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
              <br />
              회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록
              노력하겠습니다.
              <br />
              <br />
              ㆍ개인정보관리자서
              <br />
              <br />
              - 성 명 : 양명주
              <br />
              - 직 책 : 주임
              <br />
              - 연락처 : 042-716-3354
              <br />
              - 이메일 : gng365@gng365.com
              <br />
              11. 만 14 세 미만 아동의 개인정보 보호
              <br />
              회사는 법정 대리인의 동의가 필요한 만 14세 미만 아동의 회원 가입은
              받고 있지 않습니다.
              <br />
              <br />
              12. 권익침해 구제방법
              <br />
              정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
              등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의
              기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에
              만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기
              바랍니다
              <br />
              <br />
              가. 개인정보보호 종합지원 포털 (행정안전부 운영)
              <br />
              <br />
              - 소관업무 : 개인정보 침해사실 신고, 상담 신청, 자료제공
              <br />
              - 홈페이지 : www.privacy.go.kr
              <br />
              - 전화 : 02-2100-3394
              <br />
              나. 개인정보 침해신고센터 (한국인터넷진흥원 운영)
              <br />
              <br />
              - 소관업무 : 개인정보 침해사실 신고, 상담 신청
              <br />
              - 홈페이지 : privacy.kisa.or.kr
              <br />
              - 전화 : (국번없이) 118
              <br />
              - 주소 : (138-950) 서울시 송파구 중대로135 한국인터넷진흥원
              개인정보침해신고센터
              <br />
              다. 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
              <br />
              <br />
              - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
              <br />
              - 홈페이지 : privacy.kisa.or.kr
              <br />
              - 전화 : (국번없이) 118
              <br />
              - 주소 : (138-950) 서울시 송파구 중대로135 한국인터넷진흥원
              개인정보침해신고센터
              <br />
              라. 경찰청 사이버테러대응센터
              <br />
              <br />
              - 소관업무 : 개인정보 침해 관련 형사사건 문의 및 신고
              <br />
              - 홈페이지 : www.netan.go.kr
              <br />
              - 전화 : (사이버범죄) 02-393-9112 (경찰청 대표) 1566-0112
              <br />
              13. 정책 변경에 따른 공지의무
              <br />
              개인정보처리방침은 2017 년 06월 14 일 에 제정되었으며 법령/정책
              또는 보안기술의 변경에 따라 내용의 추가 및 삭제 및 수정이 있을
              시에는 변경되는 개인정보처리방침을 시행하기 최소 15일전에 해당
              품목별 인터넷페이지를 통해 내용 등을 공지하도록 하겠습니다.
              <br />
              <br />
              <br />
              제3자 정보제공 동의
              <br />
              미래앤플러스 주식회사(이하 “회사”)는 고객의 개인정보를 중요시
              하며, 『정보통신망이용 촉진 및 정보 보호 등에 관한 법률 』 상의
              개인정보보호 규정 및 정보통신부가 제정한 『 개인정보보호 및
              취급방침』을 준수하고 있습니다. 회사는 개인정보취급방침을 통해
              아래와 같이 제공해주시는 개인정보의 수집 및 이용목적과
              개인정보보호를 위한 회사의 조치 사항을 고지합니다.
              <br />
              <br />
              1. 개인정보 수집 이용 제공 등에 대한 동의
              <br />
              회사는 고객이 회사가 제공하는 안내 및 상담신청에 귀하가 제공한
              정보는 개인정보취급방침에 명시된 내용 이외의 목적으로는 사용되지
              아니합니다.
              <br />
              <br />
              2. 수집하는 개인정보의 범위 및 수집방법
              <br />
              회사에서는 귀하의 상담신청 및 서비스신청 등을 위한 필수적인 정보를
              입력 받고 있습니다.
              <br />
              <br />
              - 필수정보 : 성명, 연락처
              <br />
              - 선택정보 : 나이, 성별, 문의사항, 관심분야, 자녀성별, 자녀나이 등
              <br />
              - 서비스 이용과정이나 처리 과정에서 자동으로 생성되어 수집될 수
              있는 정보 : IP Address, 참여일시, 유입경로(참여매체)
              <br />
              3. 개인정보의 수집 및 이용목적
              <br />
              입력하신 정보는 개인별 맞춤 서비스 제공, 이벤트 안내(이메일, 전화,
              MMS 등), 상품안내를 위한 마케팅 자료(전화, 이메일, MMS 등)로 활용
              됩니다.
              <br />
              <br />
              4. 개인정보의 보유 및 이용기간
              <br />
              개인정보를 수집 동의일로부터 5년간 보유 및 활용하게 되고, 위
              보유기간의 경과 즉시 기록을 재생할 수 없는 기술적 방법을 사용하여
              동 개인정보를 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로
              명시한 기간 동안 보존합니다.
              <br />
              <br />
              ㆍ관련법령에 의한 정보보유 사유
              <br />
              <br />
              - 계약 또는 청약철회 등에 관한 기록 : 5년
              <br />
              - 대금결제 및 재화 등의 공급에 관한 기록 : 5년
              <br />
              - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
              <br />
              5. 개인정보의 파기절차 및 방법
              <br />
              원칙적으로, 개인정보 수집 및 이용목적이 달성되거나, 개인정보 보유
              및 이용기간이 종료한 경우 해당 정보를 지체 없이 파기합니다.
              파기절차 및 방법은 다음과 같습니다.
              <br />
              <br />
              ㆍ파기절차
              <br />
              <br />
              귀하가 참여한 고객정보는 목적이 달성된 후 내부 방침 및 기타 관련
              법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정기간
              저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는
              보유 이외의 다른 목적으로 이용되지 않습니다.
              <br />
              ㆍ파기방법
              <br />
              <br />
              종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
              없는 기술적 방법을 사용하여 삭제합니다.
              <br />
              6. 개인정보 수집 이용 제공 등에 대한 동의철회
              <br />
              귀하는 개인정보의 수집 이용 제공에 대한 동의를 본인 및
              법정대리인을 통해 철회할 수 있습니다. 동의철회는 명시된 문의전화
              또는 이메일 등을 통해 가능합니다.
              <br />
              <br />
              7. 개인정보의 제 3자 제공 및 취급
              <br />
              위탁회사는 귀하의 개인정보를 &lt;개인정보의 수집목적 및
              이용목적&gt;에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여
              이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다. 그러나 보다
              나은 서비스 제공을 위하여 필요할 경우, 귀하의 개인정보를
              제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를
              제공하거나 공유할 경우에는 사전에 귀하의 동의를 구하는 절차를
              거치게 됩니다.
              <br />
              <br />
              8. 개인정보 처리 업무의 위탁
              <br />
              수락자 위탁사무 및 목적 보유 및 이용기간
              <br />
              미래앤플러스㈜ 회원 정보 DB시스템 운영/관리 업무 고객 통화,
              이메일, 문자메시지 발송 제품의 상담 및 판매, 배송 등의 전반
              고객상담 및 불만처리 업무 전반 고객동의 철회 또는 위탁 계약 만료
              때 까지
              <br />
              이노페이 본인인증(금융거래, 금융서비스) 결제대행 고객동의 철회
              또는 위탁 계약 만료 때 까지
              <br />
              우체국 주문 상품의 배송 고객동의 철회 또는 위탁 계약 만료 때 까지
              <br />
              ㈜다인커뮤니케이션 시스템 유지 보수 및 관리, 상담 신청자
              개인정보를 서버에 수집,보관, 처리, 전달, 파기하는 업무 서버
              보관(IDC 센터) 고객동의 철회 또는 위탁 계약 만료 때 까지
              <br />
              ㈜미래앤플러스
              <br />
              ㈜혜윰
              <br />
              ㈜미래앤플러스 상담 신청자 개인정보를 서버에 수집,보관, 처리,
              전달, 파기하는 업무 고객동의 철회 또는 위탁 계약 만료 때 까지
              <br />
              9. 개인정보관리책임자 및 민원서비스
              <br />
              귀하의 개인정보를 보호하고 관련한 불만을 처리하기 위하여 회사는
              개인정보관리책임자를 두어 귀하의 개인정보를 관리하고 있습니다.
              <br />
              <br />
              ㆍ개인정보관리책임자
              <br />
              <br />
              - 성 명 : 김용일
              <br />
              - 직 책 : 대표이사
              <br />
              - 연락처 : 042-716-3354
              <br />
              - 이메일 : gng365@gng365.com
              <br />
              ㆍ개인정보 보호 담당부서
              <br />
              <br />
              - 부서명 : 마케팅팀
              <br />
              - 담당자 : 양명주 주임
              <br />
              - 연락처 : 042-716-3354
              <br />
              - 이메일: gng365@gng365.com
              <br />
              정보주체 께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한
              모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
              개인정보 관리책임자 및 담당부서로 문의하실 수 있습니다. 회사는
              정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
              <br />
              정보주체는 개인정보 보호법 제35조 및 정보통신망법 제30조에 따른
              개인정보의 열람 청구를 관련 부서에 할 수 있습니다. 회사는
              정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
              <br />
              <br />
              10. 개인정보 열람청구
              <br />
              정보주체는 개인정보 보호법 제35조 및 정보통신망법 제30조에 따른
              개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
              <br />
              회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록
              노력하겠습니다.
              <br />
              <br />
              ㆍ개인정보관리자서
              <br />
              <br />
              - 성 명 : 양명주
              <br />
              - 직 책 : 주임
              <br />
              - 연락처 : 042-716-3354
              <br />
              - 이메일 : gng365@gng365.com
              <br />
              11. 만 14 세 미만 아동의 개인정보 보호
              <br />
              회사는 법정 대리인의 동의가 필요한 만 14세 미만 아동의 회원 가입은
              받고 있지 않습니다.
              <br />
              <br />
              12. 권익침해 구제방법
              <br />
              정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
              등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의
              기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에
              만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기
              바랍니다
              <br />
              <br />
              가. 개인정보보호 종합지원 포털 (행정안전부 운영)
              <br />
              <br />
              - 소관업무 : 개인정보 침해사실 신고, 상담 신청, 자료제공
              <br />
              - 홈페이지 : www.privacy.go.kr
              <br />
              - 전화 : 02-2100-3394
              <br />
              나. 개인정보 침해신고센터 (한국인터넷진흥원 운영)
              <br />
              <br />
              - 소관업무 : 개인정보 침해사실 신고, 상담 신청
              <br />
              - 홈페이지 : privacy.kisa.or.kr
              <br />
              - 전화 : (국번없이) 118
              <br />
              - 주소 : (138-950) 서울시 송파구 중대로135 한국인터넷진흥원
              개인정보침해신고센터
              <br />
              다. 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
              <br />
              <br />
              - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
              <br />
              - 홈페이지 : privacy.kisa.or.kr
              <br />
              - 전화 : (국번없이) 118
              <br />
              - 주소 : (138-950) 서울시 송파구 중대로135 한국인터넷진흥원
              개인정보침해신고센터
              <br />
              라. 경찰청 사이버테러대응센터
              <br />
              <br />
              - 소관업무 : 개인정보 침해 관련 형사사건 문의 및 신고
              <br />
              - 홈페이지 : www.netan.go.kr
              <br />
              - 전화 : (사이버범죄) 02-393-9112 (경찰청 대표) 1566-0112
              <br />
              13. 정책 변경에 따른 공지의무
              <br />
              개인정보처리방침은 2017 년 06월 14 일 에 제정되었으며 법령/정책
              또는 보안기술의 변경에 따라 내용의 추가 및 삭제 및 수정이 있을
              시에는 변경되는 개인정보처리방침을 시행하기 최소 15일전에 해당
              품목별 인터넷페이지를 통해 내용 등을 공지하도록 하겠습니다.
              <br />
              <br />
              <br />
              마케팅 정보수신 동의
              <br />
              미래앤플러스 주식회사(이하 “회사”)는 고객의 개인정보를 중요시
              하며, 『정보통신망이용 촉진 및 정보 보호 등에 관한 법률 』 상의
              개인정보보호 규정 및 정보통신부가 제정한 『 개인정보보호 및
              취급방침』을 준수하고 있습니다. 회사는 개인정보취급방침을 통해
              아래와 같이 제공해주시는 개인정보의 수집 및 이용목적과
              개인정보보호를 위한 회사의 조치 사항을 고지합니다.
              <br />
              <br />
              1. 개인정보 수집 이용 제공 등에 대한 동의
              <br />
              회사는 고객이 회사가 제공하는 안내 및 상담신청에 귀하가 제공한
              정보는 개인정보취급방침에 명시된 내용 이외의 목적으로는 사용되지
              아니합니다.
              <br />
              <br />
              2. 수집하는 개인정보의 범위 및 수집방법
              <br />
              회사에서는 귀하의 상담신청 및 서비스신청 등을 위한 필수적인 정보를
              입력 받고 있습니다.
              <br />
              <br />
              - 필수정보 : 성명, 연락처
              <br />
              - 선택정보 : 나이, 성별, 문의사항, 관심분야, 자녀성별, 자녀나이 등
              <br />
              - 서비스 이용과정이나 처리 과정에서 자동으로 생성되어 수집될 수
              있는 정보 : IP Address, 참여일시, 유입경로(참여매체)
              <br />
              3. 개인정보의 수집 및 이용목적
              <br />
              입력하신 정보는 개인별 맞춤 서비스 제공, 이벤트 안내(이메일, 전화,
              MMS 등), 상품안내를 위한 마케팅 자료(전화, 이메일, MMS 등)로 활용
              됩니다.
              <br />
              <br />
              4. 개인정보의 보유 및 이용기간
              <br />
              개인정보를 수집 동의일로부터 5년간 보유 및 활용하게 되고, 위
              보유기간의 경과 즉시 기록을 재생할 수 없는 기술적 방법을 사용하여
              동 개인정보를 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로
              명시한 기간 동안 보존합니다.
              <br />
              <br />
              ㆍ관련법령에 의한 정보보유 사유
              <br />
              <br />
              - 계약 또는 청약철회 등에 관한 기록 : 5년
              <br />
              - 대금결제 및 재화 등의 공급에 관한 기록 : 5년
              <br />
              - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
              <br />
              5. 개인정보의 파기절차 및 방법
              <br />
              원칙적으로, 개인정보 수집 및 이용목적이 달성되거나, 개인정보 보유
              및 이용기간이 종료한 경우 해당 정보를 지체 없이 파기합니다.
              파기절차 및 방법은 다음과 같습니다.
              <br />
              <br />
              ㆍ파기절차
              <br />
              <br />
              귀하가 참여한 고객정보는 목적이 달성된 후 내부 방침 및 기타 관련
              법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정기간
              저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는
              보유 이외의 다른 목적으로 이용되지 않습니다.
              <br />
              ㆍ파기방법
              <br />
              <br />
              종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
              없는 기술적 방법을 사용하여 삭제합니다.
              <br />
              6. 개인정보 수집 이용 제공 등에 대한 동의철회
              <br />
              귀하는 개인정보의 수집 이용 제공에 대한 동의를 본인 및
              법정대리인을 통해 철회할 수 있습니다. 동의철회는 명시된 문의전화
              또는 이메일 등을 통해 가능합니다.
              <br />
              <br />
              7. 개인정보의 제 3자 제공 및 취급
              <br />
              위탁회사는 귀하의 개인정보를 &lt;개인정보의 수집목적 및
              이용목적&gt;에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여
              이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다. 그러나 보다
              나은 서비스 제공을 위하여 필요할 경우, 귀하의 개인정보를
              제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를
              제공하거나 공유할 경우에는 사전에 귀하의 동의를 구하는 절차를
              거치게 됩니다.
              <br />
              <br />
              8. 개인정보 처리 업무의 위탁
              <br />
              수락자 위탁사무 및 목적 보유 및 이용기간
              <br />
              미래앤플러스㈜ 회원 정보 DB시스템 운영/관리 업무 고객 통화,
              이메일, 문자메시지 발송 제품의 상담 및 판매, 배송 등의 전반
              고객상담 및 불만처리 업무 전반 고객동의 철회 또는 위탁 계약 만료
              때 까지
              <br />
              이노페이 본인인증(금융거래, 금융서비스) 결제대행 고객동의 철회
              또는 위탁 계약 만료 때 까지
              <br />
              우체국 주문 상품의 배송 고객동의 철회 또는 위탁 계약 만료 때 까지
              <br />
              ㈜다인커뮤니케이션 시스템 유지 보수 및 관리, 상담 신청자
              개인정보를 서버에 수집,보관, 처리, 전달, 파기하는 업무 서버
              보관(IDC 센터) 고객동의 철회 또는 위탁 계약 만료 때 까지
              <br />
              ㈜미래앤플러스
              <br />
              ㈜혜윰
              <br />
              ㈜미래앤플러스 상담 신청자 개인정보를 서버에 수집,보관, 처리,
              전달, 파기하는 업무 고객동의 철회 또는 위탁 계약 만료 때 까지
              <br />
              9. 개인정보관리책임자 및 민원서비스
              <br />
              귀하의 개인정보를 보호하고 관련한 불만을 처리하기 위하여 회사는
              개인정보관리책임자를 두어 귀하의 개인정보를 관리하고 있습니다.
              <br />
              <br />
              ㆍ개인정보관리책임자
              <br />
              <br />
              - 성 명 : 김용일
              <br />
              - 직 책 : 대표이사
              <br />
              - 연락처 : 042-716-3354
              <br />
              - 이메일 : gng365@gng365.com
              <br />
              ㆍ개인정보 보호 담당부서
              <br />
              <br />
              - 부서명 : 마케팅팀
              <br />
              - 담당자 : 양명주 주임
              <br />
              - 연락처 : 042-716-3354
              <br />
              - 이메일: gng365@gng365.com
              <br />
              정보주체 께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한
              모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
              개인정보 관리책임자 및 담당부서로 문의하실 수 있습니다. 회사는
              정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
              <br />
              정보주체는 개인정보 보호법 제35조 및 정보통신망법 제30조에 따른
              개인정보의 열람 청구를 관련 부서에 할 수 있습니다. 회사는
              정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
              <br />
              <br />
              10. 개인정보 열람청구
              <br />
              정보주체는 개인정보 보호법 제35조 및 정보통신망법 제30조에 따른
              개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
              <br />
              회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록
              노력하겠습니다.
              <br />
              <br />
              ㆍ개인정보관리자서
              <br />
              <br />
              - 성 명 : 양명주
              <br />
              - 직 책 : 주임
              <br />
              - 연락처 : 042-716-3354
              <br />
              - 이메일 : gng365@gng365.com
              <br />
              11. 만 14 세 미만 아동의 개인정보 보호
              <br />
              회사는 법정 대리인의 동의가 필요한 만 14세 미만 아동의 회원 가입은
              받고 있지 않습니다.
              <br />
              <br />
              12. 권익침해 구제방법
              <br />
              정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담
              등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의
              기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에
              만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기
              바랍니다
              <br />
              <br />
              가. 개인정보보호 종합지원 포털 (행정안전부 운영)
              <br />
              <br />
              - 소관업무 : 개인정보 침해사실 신고, 상담 신청, 자료제공
              <br />
              - 홈페이지 : www.privacy.go.kr
              <br />
              - 전화 : 02-2100-3394
              <br />
              나. 개인정보 침해신고센터 (한국인터넷진흥원 운영)
              <br />
              <br />
              - 소관업무 : 개인정보 침해사실 신고, 상담 신청
              <br />
              - 홈페이지 : privacy.kisa.or.kr
              <br />
              - 전화 : (국번없이) 118
              <br />
              - 주소 : (138-950) 서울시 송파구 중대로135 한국인터넷진흥원
              개인정보침해신고센터
              <br />
              다. 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
              <br />
              <br />
              - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
              <br />
              - 홈페이지 : privacy.kisa.or.kr
              <br />
              - 전화 : (국번없이) 118
              <br />
              - 주소 : (138-950) 서울시 송파구 중대로135 한국인터넷진흥원
              개인정보침해신고센터
              <br />
              라. 경찰청 사이버테러대응센터
              <br />
              <br />
              - 소관업무 : 개인정보 침해 관련 형사사건 문의 및 신고
              <br />
              - 홈페이지 : www.netan.go.kr
              <br />
              - 전화 : (사이버범죄) 02-393-9112 (경찰청 대표) 1566-0112
              <br />
              13. 정책 변경에 따른 공지의무
              <br />
              개인정보처리방침은 2017 년 06월 14 일 에 제정되었으며 법령/정책
              또는 보안기술의 변경에 따라 내용의 추가 및 삭제 및 수정이 있을
              시에는 변경되는 개인정보처리방침을 시행하기 최소 15일전에 해당
              품목별 인터넷페이지를 통해 내용 등을 공지하도록 하겠습니다.
            </p>
          </motion.div>
          <motion.button
            type="button"
            className="h-48 w-290 md:h-90 md:w-540"
            initial={{
              opacity: 0,
              y: 15,
            }}
            transition={
              visible &&
              isOpen && {
                delay: 0.5,
                duration: 0.5,
              }
            }
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                opacity: 0,
                y: 15,
              },
            }}
            animate={visible && isOpen ? 'visible' : 'hidden'}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <motion.div
              className="relative flex h-full w-full items-center justify-center rounded-full bg-gradient-to-r from-[#060b6a] to-[#1821be] text-center text-20 font-[600] tracking-tighter text-[#ffffff] shadow md:h-90 md:w-540 md:text-36"
              initial={{
                opacity: 1,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 1,
                  repeat: Infinity,
                  repeatType: 'reverse',
                },
              }}
            >
              닫기
            </motion.div>
          </motion.button>
        </div>
      </div>
    </section>
  );
}
