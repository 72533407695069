import React from 'react';
import Section1 from './section1';
import Section2 from './section2';
import Section3 from './section3';
import Section4 from './section4';
import { motion, useSpring } from 'framer-motion';
import { useForm, useWatch } from 'react-hook-form';
import classNames from '../utils/class-names';

export default function App() {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      sex: 'male',
    },
  });
  const spring = useSpring(0, { damping: 100, stiffness: 1000, bounce: 0 });
  const [index, setIndex] = React.useState(0);
  const containerRef = React.useRef();
  const questions = useWatch({
    control,
    name: 'questions',
  });
  const [isCopyrightOpen, setIsCopyrightOpen] = React.useState(false);
  const score =
    questions?.reduce(
      (previousValue, currentValue) =>
        previousValue + Number(currentValue.value),
      0,
    ) ?? 0;

  function handleNext(index) {
    setIndex(index);
    setTimeout(() => {
      spring.set(index * containerRef.current.offsetHeight);
    }, 250);
  }

  React.useLayoutEffect(() => {
    spring.on('change', (latest) => {
      containerRef.current?.scrollTo(0, latest);
    });
  }, [spring]);

  return (
    <>
      <header>
        <button
          type="button"
          className="absolute right-25 top-30 z-50 flex flex-col items-end gap-8 md:right-50 md:top-40 md:gap-15"
          onClick={() => {
            setIsCopyrightOpen(true);
          }}
        >
          <span className="h-2 w-27 bg-[#ffffff] md:h-3 md:w-60" />
          <span className="h-2 w-20 bg-[#ffffff] md:h-3 md:w-40" />
        </button>
      </header>
      <motion.div
        className={classNames(
          'absolute z-50 h-full w-full bg-[#000000] opacity-80',
          !isCopyrightOpen && 'pointer-events-none',
        )}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: isCopyrightOpen ? 0.8 : 0,
          transition: {
            type: 'tween',
            duration: 0.15,
          },
        }}
        onClick={() => {
          setIsCopyrightOpen(false);
        }}
      />
      <motion.div
        className="absolute top-0 z-50 w-full bg-[#ffffff] py-20 md:py-40"
        initial={{
          y: '-100%',
        }}
        animate={{
          y: isCopyrightOpen ? '0%' : '-100%',
          transition: {
            type: 'tween',
            duration: 0.15,
          },
        }}
      >
        <div className="flex items-center justify-center gap-10 text-center md:gap-30">
          <span className="text-12 font-[400] tracking-tighter text-[#0c0c0c] md:text-22">
            상호명 : 미래앤플러스
          </span>
          <span className="text-12 font-[400] tracking-tighter text-[#0c0c0c] md:text-22">
            대표자 : 김동현
          </span>
          <span className="text-12 font-[400] tracking-tighter text-[#0c0c0c] md:text-22">
            사업자등록번호 : 877-87-02388
          </span>
        </div>
      </motion.div>
      <main>
        <form
          name="form"
          ref={containerRef}
          className="h-screen overflow-hidden"
          style={{ height: window.innerHeight }}
          onSubmit={handleSubmit((data, event) => {
            fetch('/submit.php', {
              method: 'POST',
              body: new FormData(event.target),
            });
            handleNext(3);
          })}
        >
          <Section1
            onNext={() => {
              handleNext(1);
            }}
            visible={index === 0}
          />
          <Section2
            onNext={() => {
              handleNext(2);
            }}
            visible={index === 1}
            register={register}
            score={score}
          />
          <Section3 visible={index === 2} register={register} score={score} />
          <Section4 visible={index === 3} score={score} />
        </form>
      </main>
    </>
  );
}
