import React from 'react';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import section1BgImage from '../assets/images/section-1-bg.png';
import section1BgMImage from '../assets/images/section-1-bg-m.png';

export default function Section1({ onNext, visible }) {
  const { register } = useForm();
  return (
    <section
      className="relative flex h-screen w-screen flex-col items-center overflow-hidden py-50 md:py-[2%] md:pt-100"
      style={{ height: window.innerHeight }}
    >
      <motion.div
        className="absolute inset-0 h-full w-full"
        transition={
          visible && {
            delay: 1,
            duration: 0.5,
          }
        }
        initial={{
          opacity: 0,
        }}
        variants={{
          visible: {
            opacity: 1,
          },
          hidden: {
            opacity: 0,
          },
        }}
        animate={visible ? 'visible' : 'hidden'}
      >
        <motion.img
          src={section1BgImage}
          alt="background"
          className="hidden h-full w-full object-cover md:block"
          initial={{
            opacity: 0.75,
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
          animate={{
            opacity: 1,
          }}
        />
        <motion.img
          src={section1BgMImage}
          alt="background"
          className="h-full w-full object-cover md:hidden"
          initial={{
            opacity: 0.75,
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
          animate={{
            opacity: 1,
          }}
        />
      </motion.div>
      <div className="flex h-full w-full max-w-[1060px] flex-col items-center px-20">
        <motion.div
          className="absolute inset-x-0 top-25 md:top-35"
          transition={
            visible && {
              delay: 2,
              duration: 0.5,
            }
          }
          initial={{
            opacity: 0,
            y: 15,
          }}
          variants={{
            visible: {
              opacity: 1,
              y: 0,
            },
            hidden: {
              delay: 1.5,
              duration: 0.5,
            },
          }}
          animate={visible ? 'visible' : 'hidden'}
        >
          <motion.p
            className="text-center text-20 font-[600] text-[#4de5ff] underline md:text-24"
            initial={{
              opacity: 0.5,
            }}
            animate={{
              opacity: 0.85,
              transition: {
                delay: 3,
                duration: 1,
                repeat: Infinity,
                repeatType: 'reverse',
              },
            }}
          >
            전립선 자가진단 무료 설문조사
          </motion.p>
        </motion.div>
        <div className="relative flex flex-1 flex-col items-center justify-center gap-10 md:gap-30">
          <motion.div
            className="flex flex-col items-center justify-center gap-25"
            transition={
              visible && {
                delay: 1.5,
                duration: 0.5,
              }
            }
            initial={{
              opacity: 0,
              y: 15,
            }}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                delay: 1.5,
                duration: 0.5,
              },
            }}
            animate={visible ? 'visible' : 'hidden'}
          >
            <h2 className="text-center text-40 font-[600] leading-[44px] tracking-tighter text-[#ffffff] md:hidden md:text-70 md:leading-[90px]">
              전립선 자가진단
              <br />
              무료설문조사
            </h2>
            <h2 className="hidden text-center text-32 font-[600] leading-[40px] tracking-tighter text-[#ffffff] md:block md:text-70 md:leading-[90px]">
              전립선 자가진단 무료설문조사
            </h2>
          </motion.div>
          <div className="flex flex-col gap-10 md:gap-32">
            <motion.p
              className="break-keep text-center text-18 font-[400] tracking-tighter text-[#ffffff] md:text-26"
              transition={
                visible && {
                  delay: 1.75,
                  duration: 0.5,
                }
              }
              initial={{
                opacity: 0,
                y: 15,
              }}
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                },
                hidden: {
                  opacity: 0,
                  y: 15,
                },
              }}
              animate={visible ? 'visible' : 'hidden'}
            >
              본 조사는 대한민국의 전립선비대증 실태를 파악하기 위해
              <br />
              <b className="font-[600]">IPSS공법을 활용한 설문조사</b>입니다.
            </motion.p>
            <motion.p
              className="break-keep text-center text-14 font-[400] tracking-tighter text-[#ffffff] md:hidden md:text-18"
              transition={
                visible && {
                  delay: 1.75,
                  duration: 0.5,
                }
              }
              initial={{
                opacity: 0,
                y: 15,
              }}
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                },
                hidden: {
                  opacity: 0,
                  y: 15,
                },
              }}
              animate={visible ? 'visible' : 'hidden'}
            >
              *IPSS(국제전립선 증상 점수표) : 전립선 비대증의 심각도와 치료의
              효과를 평가하기 위해 세계보건기구(WHO)에서 채택한 평가 도구로
              대중적인 전립선 비대증 자가진단표
            </motion.p>
            <motion.p
              className="hidden break-keep text-center text-11 font-[400] tracking-tighter text-[#ffffff] md:block md:text-18"
              transition={
                visible && {
                  delay: 1.75,
                  duration: 0.5,
                }
              }
              initial={{
                opacity: 0,
                y: 15,
              }}
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                },
                hidden: {
                  opacity: 0,
                  y: 15,
                },
              }}
              animate={visible ? 'visible' : 'hidden'}
            >
              *IPSS(국제전립선 증상 점수표) : 전립선 비대증의 심각도와 치료의
              효과를 평가하기 위해
              <br />
              세계보건기구(WHO)에서 채택한 평가 도구로 대중적인 전립선 비대증
              자가진단표
            </motion.p>
            <motion.div
              className="flex w-full flex-col items-center justify-center gap-5 rounded-10 border border-[#ffffff] py-15 text-center md:gap-10 md:rounded-30 md:px-40"
              transition={
                visible && {
                  delay: 1.75,
                  duration: 0.5,
                }
              }
              initial={{
                opacity: 0,
                y: 15,
              }}
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                },
                hidden: {
                  opacity: 0,
                  y: 15,
                },
              }}
              animate={visible ? 'visible' : 'hidden'}
            >
              <p className="text-center text-18 font-[600] tracking-tighter text-[#ffffff] md:text-34">
                참여하시면, 소정의 선물을 드립니다.
              </p>
              <p className="text-center text-14 tracking-tighter text-[#ffffff] md:text-22">
                <b className="font-[600]">대상</b> : 대한민국 60대 이상 남성 |{' '}
                <b className="font-[600]">혜택</b> : 설문조사 완료자 대상
              </p>
              <p className="rounded-8 bg-[#ffffff] px-10 py-3 text-18 tracking-tighter text-[#e84807] md:rounded-20 md:px-20 md:py-5 md:text-22">
                <b className="font-[600]">진행시간</b> : 약 30초
              </p>
              <label className="flex cursor-pointer items-start gap-5 text-left text-12 tracking-tighter text-[#ffffff] md:gap-10 md:text-18">
                <input type="checkbox" {...register('check0')} defaultChecked />
                <span>
                  <b className="font-[600]">개인정보 수집 및 이용동의 [필수]</b>
                  <br />
                  개인정보 수집 및 이용에 대한 안내
                </span>
              </label>
            </motion.div>
          </div>
        </div>
        <motion.button
          type="button"
          className="relative flex h-48 w-290 md:h-90 md:w-540 md:text-36"
          initial={{
            opacity: 0,
            y: 15,
          }}
          transition={
            visible && {
              delay: 2.5,
              duration: 0.5,
            }
          }
          variants={{
            visible: {
              opacity: 1,
              y: 0,
            },
            hidden: {
              opacity: 0,
              y: 15,
            },
          }}
          animate={visible ? 'visible' : 'hidden'}
          onClick={() => {
            if (!window.document.form.check0.checked) {
              alert('개인정보 수집 및 이용동의를 체크해주세요.');
              return;
            }
            onNext();
          }}
        >
          <div className="relative flex h-full w-full items-center justify-center rounded-full bg-gradient-to-r from-[#e64405] to-[#ff6e1f] text-center text-24 font-[600] tracking-tighter text-[#ffffff] shadow md:text-36">
            다음으로
            <span className="absolute right-20 text-16 tracking-tighter md:right-35 md:text-30">
              →
            </span>
          </div>
        </motion.button>
      </div>
    </section>
  );
}
