export default function classNames(...strings) {
  return strings
    .reduce((previousValue, currentValue) => {
      if (currentValue) {
        return `${previousValue} ${currentValue}`;
      }
      return previousValue;
    }, '')
    .trim();
}
